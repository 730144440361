import React from "react";
import { Nav, Logo, NavLink, Bars, NavMenu, NavBtn } from "./HeaderElements";
import { StyledLink } from './HeaderElements';


const Header = ({ toggle }) => {
  return (
    <div className="Container" style={{padding: 0}}>
      <Nav>
        <Logo to="/">
          <img
            src="/logo.png"
            alt="logo"
          />
        </Logo>
        <NavMenu>
          <NavLink className="menu-item" to="projects">
            Projects
          </NavLink>
          <NavLink className="menu-item" to="about">
            About
          </NavLink>
          <NavLink className="menu-item" to="contact">
            Contact
          </NavLink>
          <StyledLink 
            href="https://medium.com/@mohdtalib.dev" 
            className="menu-item" 
            target="_blank">
              My Blogs
          </StyledLink>

        </NavMenu>
        <NavBtn>
        <StyledLink 
            href={`${process.env.PUBLIC_URL}/cv.pdf`} 
            className="menu-item" 
            target="_blank" 
            rel="noopener noreferrer" 
            download="Mohd_Talib_CV.pdf">
              Download CV
          </StyledLink>
        </NavBtn>
        <Bars onClick={toggle} />
      </Nav>
    </div>
  );
};

export default Header;
