import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/Home";


function App() {
  return (
      <Home />
  );
}

export default App;
