import React from 'react';
import { Card, Button, Row, Col, Container } from 'react-bootstrap';
import './Project.css';
import { FaLink, FaGithub } from "react-icons/fa";


const ProjectCard = ({ project }) => {
  return (
    <Card className="project-card">
      <Card.Img className='project-image' variant="top" src={project.imageUrl} />
      <Card.Body>
        <Card.Title>{project.name}</Card.Title>
        <a
              href={project.demo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLink />
        </a>
        <a
              href={project.githubLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
        </a>
        <Card.Text>
          {project.description}
        </Card.Text>
        
        {/* <a
              href={project.demo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLink />
        </a>
        <a
              href={project.githubLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
        </a> */}
      </Card.Body>
    </Card>
  );
};

const ProjectsSection = ({ projects }) => {
  // Group projects into rows of 3
  const projectRows = projects.reduce((rows, project, idx) => {
    if (idx % 3 === 0) rows.push([]);
    rows[rows.length - 1].push(project);
    return rows;
  }, []);

  return (
    <Container className="project-container shadow-sm" id="projects">
      <h2>Projects</h2>
      {projectRows.map((projectGroup, idx) => (
        <Row key={idx} className="g-4 my-5">
          {projectGroup.map((project, idx) => (
            <Col md={4} key={idx}>
              <ProjectCard project={project} />
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
};

// Sample project data with dummy images
const sampleProjects = [
  {
    name: 'project_ott',
    description: 'A video streaming platform built using React.js and Node.js. ',
    imageUrl: "https://res.cloudinary.com/friend-zone/image/upload/v1705793434/ott_project_image_1_s7ueks.png", // Dummy image placeholder
    demo: "https://ott-project-8ea55.web.app/",
    githubLink: 'https://github.com/dev-talib/ott_project_frontend'
  },
  {
    name: 'Video calling app',
    description: 'Real-time video chat built with Node.js, Socket.io, and WebRTC. Simple, powerful, and open-source. ',
    imageUrl: 'https://imgs.search.brave.com/dlO2khJ-a7ReRhao6xkaMOCSf7L20HTk8PoWJonDfJU/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jZG4u/cGl4YWJheS5jb20v/cGhvdG8vMjAyMS8w/MS8zMC8wMy8zMy92/aWRlby1jYWxsLTU5/NjI3MzNfNjQwLmpw/Zw', // Dummy image placeholder
    demo: "https://mtm-video-streaming-io.onrender.com/",
    githubLink: 'https://github.com/dev-talib/Video_Chat_App_NodeJs'
  },
  {
    name: 'Daily Hunt',
    description: 'A simple News app created using React js and used News Api to fetch news content.',
    imageUrl: 'https://res.cloudinary.com/friend-zone/image/upload/v1706265127/dailyhunt-news_adgjmy.png', // Dummy image placeholder
    demo: "https://react-news-app-ffda2.web.app/",
    githubLink: 'https://react-news-app-ffda2.web.app/'
  },
  {
    name: 'Realtime Tik Tak Toe',
    description: 'Node.js and Socket.io  powers realtime tik tak toe game',
    imageUrl:'https://media.istockphoto.com/id/168678735/photo/metal-tic-tac-toe-game-pieces.webp?b=1&s=170667a&w=0&k=20&c=I_Lr3gpHLPuxMtCQbKSgqUUG2iSQMdBHd0sMnbncCcE=', // Dummy image placeholder
    demo: 'https://multiplayer-tic-tac-toe-wo35.onrender.com/',
    githubLink: 'https://github.com/dev-talib/realtime-multiplayer-tic-tac-toe'
  },
  {
    name: 'node-jwt-auth-api',
    description: 'Node.js – JWT Authentication & Authorization api example with JSONWebToken ',
    imageUrl: 'https://imgs.search.brave.com/b7T3egQc-4zmOJMIWn0Sl4fmC3uSQt5l1HJWxMyOX4A/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMudG9wdGFsLmlv/L2ltYWdlcz91cmw9/aHR0cHM6Ly91cGxv/YWRzLnRvcHRhbC5p/by9ibG9nL2ltYWdl/Lzk1Ni90b3B0YWwt/YmxvZy1pbWFnZS0x/NDI2Njc2Mzk1MjIy/LmpwZWc', // Dummy image placeholder
    dem: "https://github.com/dev-talib/node-jwt-auth-api",
    githubLink: 'https://github.com/dev-talib/node-jwt-auth-api'
  },
  {
    name: 'react-redux-auth-example ',
    description: 'This repository provides an example of implementing user authentication in your React app using Redux. ',
    imageUrl: 'https://blog.knoldus.com/wp-content/uploads/2022/06/redux.jpg', // Dummy image placeholder
    demo: 'https://github.com/dev-talib/react-redux-auth-example',
    githubLink: 'https://github.com/dev-talib/react-redux-auth-example'
  }
//   // ... add more projects as needed


];

export default function App() {
  return <ProjectsSection projects={sampleProjects} />;
}
